import React from "react";
import PropTypes from "prop-types";
import Loader from "components/Loader";
import style from "./MasterButton.module.scss";
import classNames from "classnames";

function MasterButton(props) {
  const { buttonText, isLoading, className, onClick, type, isDisabled } = props;

  return (
    <button
      type={type}
      onClick={onClick}
      className={classNames(className, {
        [style.disabled]: isDisabled,
      })}
      disabled={isLoading || isDisabled}
    >
      {isLoading ? <Loader size={16} color="white" /> : buttonText}
    </button>
  );
}

export default MasterButton;

MasterButton.propTypes = {
  buttonText: PropTypes.any,
  isLoading: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  isDisabled: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  className: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  onClick: PropTypes.func,
  type: PropTypes.string,
};

MasterButton.defaultProps = {
  buttonText: "",
  isLoading: false,
  isDisabled: false,
  className: "",
  onClick: () => {},
  type: "button",
};
