import React from "react";
import ReactDOM from "react-dom/client";
import * as Sentry from "@sentry/react";

Sentry.init({
  dsn: "https://47c62f8c22d17167d5f4f8a0714ffdca@o336897.ingest.us.sentry.io/4507327679168512",
  integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

const rootEl = document.getElementById("app-site");

const MainApp = require("./MainApp").default;

const root = ReactDOM.createRoot(rootEl);
root.render(<MainApp />);

// Create a reusable render method that we can call more than once
// let render = () => {
// Dynamically import our main App component, and render it
// };

// if (module.hot) {
//   module.hot.accept('./MainApp', () => {
//     const NextApp = require('./MainApp').default;
//     render(<NextApp />, rootEl);
//   });
// }

// render();
