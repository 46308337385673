import React from "react";
import PropTypes from "prop-types";
import IntlMessages from "util/IntlMessages";
import MasterButton from "components/Common/MasterButton";
import Icons from "components/Common/Icons";
import Avatar from "components/Common/Avatar";
import classNames from "classnames";
import { sanitizeUrlProtocol } from "util/helpers";

function TenantCard(props) {
  const { tenantDetails, onSelectTenant, allowSelect, allowEdit, onEditClick } = props;
  const tenantUrl = sanitizeUrlProtocol(tenantDetails.eventDomainName);

  return (
    <div
      className={classNames("bg-backgroundGrey flex md:p-4 items-center justify-between rounded-3xl border border-solid border-borderGrey tenantCard h-full", { "cursor-pointer": allowSelect })}
      onClick={allowSelect ? () => onSelectTenant() : undefined}
    >
      <div className="w-full flex items-center justify-start relative md:!p-0 p-3 overflow-hidden">
        <Avatar src={tenantDetails.logo && tenantDetails.logo.imageUrl} className="!h-[56px] !w-[56px] bg-blankImage rounded-full">
          {tenantDetails.tenantName[0].toUpperCase()}
        </Avatar>
        <div className="flex flex-col mx-2 overflow-hidden">
          <span className={classNames("typo-18-700 truncate", { "text-wrap": !allowEdit })}>{tenantDetails.tenantName}</span>
          <span className="typo-14-400 text-neutrals4 text-wrap tenantLink z-20 break-all" onClick={() => window.open(`https://${tenantUrl}`, "_blank")}>
            {tenantUrl}
          </span>
        </div>
        <div className="w-full h-full absolute left-0 md:hidden" onClick={onSelectTenant} />
      </div>
      {/* {allowSelect && <MasterButton buttonText={<IntlMessages id="button.select" />} onClick={onSelectTenant} className="h7-btn blue h7-btn-outline ml-1 hidden md:block" />} */}
      {allowEdit && <Icons type="edit" size="xl" className="text-primary cursor-pointer mx-3 lg:!mx-0 flex-shrink-0" onClick={onEditClick} />}
    </div>
  );
}

export default TenantCard;

TenantCard.propTypes = {
  tenantDetails: PropTypes.object.isRequired,
  onSelectTenant: PropTypes.func,
  allowSelect: PropTypes.bool,
  allowEdit: PropTypes.bool,
  onEditClick: PropTypes.func,
};

TenantCard.defaultProps = {
  onSelectTenant: undefined,
  allowSelect: true,
  allowEdit: false,
  onEditClick: undefined,
};
