import { webhooksClient } from "./http";

class WebhooksManager {
  getWebhooksEventTypes = async (tenantId) => {
    if (tenantId) {
      return await webhooksClient.get(`/tenants/${tenantId}/webhooks/event-types`);
    }
    return null;
  };

  getWebhooksSubscriptions = async (tenantId, skip = 0, take = 10) => {
    if (tenantId) {
      return await webhooksClient.get(`/tenants/${tenantId}/webhooks-subscriptions`, { params: { skip, take } });
    }
    return null;
  };

  addWebhooksSubscriptions = async (tenantId, data) => {
    if (tenantId && !!data) {
      return await webhooksClient.post(`/tenants/${tenantId}/webhooks-subscriptions`, data);
    }
    return null;
  };

  updateWebhooksSubscriptions = async (tenantId, subscriptionId, data) => {
    if (tenantId && !!subscriptionId && !!data) {
      return await webhooksClient.put(`/tenants/${tenantId}/webhooks-subscriptions/${subscriptionId}`, data);
    }
    return null;
  };

  getWebhooksSubscriptionsDetails = async (tenantId, subscriptionId) => {
    if (tenantId && !!subscriptionId) {
      return await webhooksClient.get(`/tenants/${tenantId}/webhooks-subscriptions/${subscriptionId}`);
    }
    return null;
  };

  activateWebhooksSubscriptions = async (tenantId, subscriptionId) => {
    if (tenantId && subscriptionId) {
      return await webhooksClient.post(`/tenants/${tenantId}/webhooks-subscriptions/${subscriptionId}/activate`, null, { params: { tenantId } });
    }
    return null;
  };

  deactivateWebhooksSubscriptions = async (tenantId, subscriptionId) => {
    if (tenantId && !!subscriptionId) {
      return await webhooksClient.post(`/tenants/${tenantId}/webhooks-subscriptions/${subscriptionId}/deactivate`, null, { params: { tenantId } });
    }
    return null;
  };

  getWebhooksPayload = async (tenantId, webhookEventCode, version = "") => {
    if (tenantId && !!webhookEventCode) {
      return await webhooksClient.get(`/tenants/${tenantId}/webhooks/${webhookEventCode}/payload`, { params: { version } });
    }
    return null;
  };

  getWebhooksActivities = async (tenantId, subscriptionId, skip = 0, take = 10, status = "") => {
    if (tenantId && !!subscriptionId) {
      return await webhooksClient.get(`/tenants/${tenantId}/webhooks-subscriptions/${subscriptionId}/activity`, { params: { skip, take, status } });
    }
    return null;
  };

  getWebhooksActivityById = async (tenantId, activityId) => {
    if (tenantId && !!activityId) {
      return await webhooksClient.get(`/tenants/${tenantId}/webhooks-activity/${activityId}`);
    }
    return null;
  };

  sendWebhookVerificationCode = async (tenantId, subscriptionId) => {
    if (tenantId && !!subscriptionId) {
      return await webhooksClient.post(`/tenants/${tenantId}/webhooks-subscriptions/${subscriptionId}/verification-codes`);
    }
    return null;
  };

  verifyWebhookVerificationCode = async (tenantId, subscriptionId, token) => {
    if (tenantId && !!subscriptionId && !!token) {
      return await webhooksClient.post(`/tenants/${tenantId}/webhooks-subscriptions/${subscriptionId}/verify`, null, { params: { token } });
    }
    return null;
  };

  sendTestData = async (tenantId, subscriptionId, eventType) => {
    if (tenantId && !!subscriptionId && !!eventType) {
      return await webhooksClient.post(`/tenants/${tenantId}/webhooks-subscriptions/${subscriptionId}/event-types/${eventType}/test`);
    }
    return null;
  };
}

const instance = new WebhooksManager();

export default instance;
