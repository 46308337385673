import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import styles from "./label.module.scss";

function Label(props) {
  const { text, className, isRequired, type, isHide } = props;
  return isHide ? null : (
    <span
      className={classNames([
        className,
        {
          [styles.input]: type === "input",
          [styles.inputError]: type === "input-error",
          [styles.checkbox]: type === "checkbox",
          [styles.inputHelperLabel]: type === "helperText",
          [styles.inputBig]: type === "inputBig",
        },
      ])}
    >
      {text}
      {isRequired && <sup className={styles.inputError}>{"*"}</sup>}
    </span>
  );
}

export default Label;

Label.propTypes = {
  type: PropTypes.oneOf(["input", "table", "input-error", "checkbox", "helperText", "inputBig"]),
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  className: PropTypes.string,
  isRequired: PropTypes.bool,
  isHide: PropTypes.bool,
};

Label.defaultProps = {
  text: "",
  className: "",
  isRequired: false,
  isHide: false,
};
