import React from "react";
import IntlMessages from "./IntlMessages";

const localeKey = "h7-localeId";

export function setLocaleToStorage(localeId) {
  sessionStorage.setItem(localeKey, localeId);
}

export function getLocaleFromStorage() {
  return sessionStorage.getItem(localeKey);
}

export function removeLocaleFromStorage() {
  sessionStorage.removeItem(localeKey);
}

export const languageData = [
  {
    languageId: "saudi-arabia",
    locale: "ar",
    name: "Arabic",
    icon: "sa-arabic",
  },
  {
    languageId: "chinese",
    locale: "zh",
    name: "Chinese",
    icon: "cn-chinese",
  },
  {
    languageId: "english",
    locale: "en",
    name: "English",
    icon: "us-en",
  },
  {
    languageId: "spanish",
    locale: "es",
    name: "Spanish",
    icon: "es-spanish",
  },
  //   {
  //     languageId: "french",
  //     locale: "fr",
  //     name: "French",
  //     icon: "fr",
  //   },
  // {
  //     languageId: 'italian',
  //     locale: 'it',
  //     name: 'Italian',
  //     icon: 'it'
  // },
];

export const defaultLanguage = languageData[2];

export function getOrderLocaleStatus(status) {
  let orderStatus = "";
  switch (status) {
    case "Confirmed":
      orderStatus = "content.sidebar.menu.filter.confirmed";
      break;
    case "Cancelled":
      orderStatus = "content.sidebar.menu.filter.canceled";
      break;
    default:
      orderStatus = "";
      break;
  }

  return !!orderStatus ? <IntlMessages id={orderStatus} /> : "";
}

export function getCampaignLocaleStatus(status) {
  let campaignStatus = "";
  switch (status) {
    case "In-Progress":
      campaignStatus = "containers.Boomerang.campaign.status.inProgress";
      break;
    case "Draft":
      campaignStatus = "containers.Boomerang.campaign.status.draft";
      break;
    case "Complete":
      campaignStatus = "containers.Boomerang.campaign.status.complete";
      break;
    case "Published":
      campaignStatus = "containers.Boomerang.campaign.status.published";
      break;
    case "Suspended":
      campaignStatus = "containers.Boomerang.campaign.status.suspended";
      break;
    default:
      campaignStatus = "";
      break;
  }

  return !!campaignStatus ? <IntlMessages id={campaignStatus} /> : "";
}

export function getGuestLocalStatus(status) {
  let guestStatus = "";

  switch (status) {
    case "scanned":
      guestStatus = "containers.scanTicket.scanned";
      break;
    case "exited":
      guestStatus = "containers.scanTicket.exited";
      break;
    case "pending":
      guestStatus = "containers.scanTicket.pending";
      break;
    case "cancelled":
      guestStatus = "containers.scanTicket.cancelled";
      break;
    default:
      guestStatus = status;
      break;
  }

  return !!guestStatus ? <IntlMessages id={guestStatus} /> : "";
}

export function getLineItemLocaleStatus(status) {
  let lineItemStatus = "";
  switch (status) {
    case "CONFIRMED":
      lineItemStatus = "content.sidebar.menu.filter.confirmed";
      break;
    case "CANCELLED":
      lineItemStatus = "content.sidebar.menu.filter.canceled";
      break;
    default:
      lineItemStatus = "";
      break;
  }

  return !!lineItemStatus ? <IntlMessages id={lineItemStatus} /> : status;
}

export function getPaymentMethodLocale(method) {
  let paymentMethod = "";
  switch (method) {
    case "Credit Card":
      paymentMethod = "container.event.report.ticketTypeReport.creditCard";
      break;
    case "Cash":
      paymentMethod = "container.event.report.ticketTypeReport.cash";
      break;
    default:
      paymentMethod = "";
      break;
  }

  return !!paymentMethod ? <IntlMessages id={paymentMethod} /> : method;
}
