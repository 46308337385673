import React from "react";
import * as msal from "@azure/msal-browser";
import jwtDecode from "jwt-decode";
import { msalConfig, loginRequest } from "./AuthConfigAAD";
import { setInitUrl, userIdentitySignInSuccess, userSignOutSuccess } from "actions/index";

export const h7Application = new msal.PublicClientApplication(msalConfig);

class AuthServices extends React.Component {
  init() {
    this.handleAuthentication();
  }

  logIn = async () => {
    localStorage.clear();
    sessionStorage.clear();
    this.clearAllCookies();
    this.clearCacheData();
    await h7Application.loginRedirect(loginRequest);
  };

  clearCacheData = () => {
    caches.keys().then((names) => {
      names.forEach((name) => {
        caches.delete(name);
      });
    });
  };

  clearAllCookies = () => {
    let cookies = document.cookie.split(";");
    for (let i = 0; i < cookies.length; i++) {
      let cookie = cookies[i];
      let eqPos = cookie.indexOf("=");
      let cookieName = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
      document.cookie = cookieName + "=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
    }
  };

  logOut = async () => {
    this.setSession(null);
    await h7Application.logoutRedirect();
  };

  handleAuthentication = () => {
    const accessToken = this.getAccessToken();

    if (!accessToken) {
      return "noAccessToken";
    }

    if (this.isAuthTokenValid(accessToken)) {
      this.setSession(accessToken);
      return "Authenticated";
    } else {
      this.setSession(null);
      return "notValidToken";
    }
  };

  setSession = (accessToken) => {
    if (accessToken) {
      localStorage.setItem("access_token", accessToken);
    } else {
      setInitUrl("");
      userSignOutSuccess();
      localStorage.removeItem("access_token");
      localStorage.removeItem("tenant_id");
      localStorage.removeItem("user_id");
    }
  };

  isAuthTokenValid = (accessToken) => {
    if (!accessToken) {
      return false;
    }
    const decoded = jwtDecode(accessToken);
    const currentTime = Date.now() / 1000;
    if (decoded.exp < currentTime) {
      console.warn("access token expired");
      return false;
    }
    return true;
  };

  getAccessToken = () => {
    const accessToken = localStorage.getItem("access_token");
    const sessionAccessToken = sessionStorage.getItem("access_token");
    if (accessToken !== null) {
      return accessToken;
    } else if (sessionAccessToken !== null) {
      return sessionAccessToken;
    } else {
      return null;
    }
  };

  getDecodedAccessToken = () => {
    const accessToken = this.getAccessToken();
    const isAuthTokenValid = this.isAuthTokenValid(accessToken);
    if (isAuthTokenValid) {
      const decoded = jwtDecode(accessToken);
      return decoded;
    }
    return false;
  };

  getTokenRedirect = async (tokenRequest, accountId, isHandleError = true) => {
    tokenRequest.account = await h7Application.getAccountByHomeId(accountId);
    return new Promise((resolve, reject) => {
      h7Application
        .acquireTokenSilent(tokenRequest)
        .then((response) => {
          if (!response.accessToken || response.accessToken === "") {
            throw new msal.InteractionRequiredAuthError();
          }
          if (this.isAuthTokenValid(response.accessToken)) {
            this.setSession(response.accessToken);
            const decodedToken = this.getDecodedAccessToken();
            localStorage.setItem("user_id", decodedToken.sub);
          }
          resolve(response);
        })
        .catch((error) => {
          if (isHandleError) {
            if (error instanceof msal.InteractionRequiredAuthError) {
              reject(h7Application.acquireTokenRedirect(tokenRequest));
              return h7Application.acquireTokenRedirect(tokenRequest);
            } else {
              console.log("acquireTokenSilent error", error);
            }
          }
          reject(error);
        });
    });
  };

  getTokenByRedirectingToOIDC = (tokenRequest) => h7Application.acquireTokenRedirect(tokenRequest);

  isAboutToExpire = () => {
    const accessToken = this.getAccessToken();
    if (!accessToken) {
      return false;
    }
    const decoded = jwtDecode(accessToken);
    const currentTime = Date.now() / 1000;
    const fiveMin = 5 * 60;
    if (decoded.exp < currentTime + fiveMin) {
      return true;
    }
    return false;
  };
}

const instance = new AuthServices();

export default instance;
